import React from "react";
import Portal from "./Portal";
import PortalNewsStaffGrowthBlue, {
	title as blueTitle,
} from "./PortalNewsStaffGrowthBlue";
import PortalNewsHiringEnd, { title as endTitle } from "./PortalNewsHiringEnd";

import PortalNewsETRLeak, { title as leakTitle } from "./PortalNewsETRLeak";
import PortalNewsOnboardingChecklist, {
	title as onboardTitle,
} from "./PortalNewsOnboardingChecklist";

import PortalNewsAgusutaLostFound, {
	title as lostFoundTitle,
} from "./PortalNewsAgusutaLostFound";

import PortalNewsChimeraHealthNotice, {
	title as healthNoticeTitle,
} from "./PortalNewsChimeraHealthNotice";

import PortalNewsBlueDragonUpdate, {
	title as blueDragonTitle,
} from "./PortalNewsBlueDragonUpdate";

import AutomatedDocumentResponse, {
	title as documentResponseTitle,
} from "./AutomatedDocumentResponse";

import WhistleblowerLeak, {
	title as whistleblowerTitle,
} from "./PortalNewsWhistleblowerLeak";

import PortalTragicIncident, {
	title as tragicIncidentTitle,
} from "./PortalTragicIncident";

import PortalNewsPhase2BrainWorm, {
	title as phase2BrainWormTitle,
} from "./PortalNewsPhase2BrainWorm";

const PortalEmployee = () => {
	const tabs = [
		{ id: "home", label: "Home" },
		{ id: "resources", label: "Resources" },
		{ id: "help", label: "Help" },
		// { id: "benefits", label: "Benefits" },
	];

	const newsArticles = [
		{ component: PortalNewsPhase2BrainWorm, title: phase2BrainWormTitle },
		{ component: PortalTragicIncident, title: tragicIncidentTitle },
		{ component: WhistleblowerLeak, title: whistleblowerTitle },
		{ component: AutomatedDocumentResponse, title: documentResponseTitle },
		{ component: PortalNewsBlueDragonUpdate, title: blueDragonTitle },
		{ component: PortalNewsChimeraHealthNotice, title: healthNoticeTitle },
		{ component: PortalNewsAgusutaLostFound, title: lostFoundTitle },
		{ component: PortalNewsOnboardingChecklist, title: onboardTitle },
		{ component: PortalNewsETRLeak, title: leakTitle },
		{ component: PortalNewsStaffGrowthBlue, title: blueTitle },
		{ component: PortalNewsHiringEnd, title: endTitle },
	];
	const resources = [
		{
			name: "Blue Dragon Facility Footage",
			description: "Incident 4 - Camera 13",
			type: "JPG",
			createdDate: "2024-08-24",
			modifiedDate: "2024-08-24",
			link: "/media/documents/BlueDragonFacility-BD13.jpg",
		},
		{
			name: "BSFCC Construction",
			description: "Summer construction",
			type: "JPG",
			createdDate: "2023-07-21",
			modifiedDate: "2022-10-19",
			link: "/media/documents/BlueSeaBargeConstruction.jpg",
		},
		{
			name: "EIP Augusta Leakage",
			description: "Environmental Impact Report",
			type: "PDF",
			createdDate: "2023-10-05",
			modifiedDate: "2022-10-08",
			link: "/media/documents/Mt_Augusta_Energy_Storage_Leakage_Incident.pdf",
		},
		{
			name: "Study 4-A7 Media Sample 1",
			description: "FOUO TLP RED",
			type: "PDF",
			createdDate: "2022-12-23",
			modifiedDate: "2023-10-08",
			link: "/media/documents/Study_4-A7_FOUO_Media_Sample_1.jpg",
		},
		{
			name: "Study 4-A7 Media Sample 2",
			description: "FOUO TLP RED",
			type: "PDF",
			createdDate: "2022-12-23",
			modifiedDate: "2023-10-08",
			link: "/media/documents/Study_4-A7_FOUO_Media_Sample_2.jpg",
		},
		{
			name: "Study 4-A7 Media Sample 3",
			description: "FOUO TLP RED",
			type: "PDF",
			createdDate: "2022-12-23",
			modifiedDate: "2023-10-08",
			link: "/media/documents/Study_4-A7_FOUO_Media_Sample_3.jpg",
		},
		{
			name: "Incident Report 7 DRAFT_April_2023",
			description: "FOUO TLP AMBER",
			type: "PDF",
			createdDate: "2023-04-22",
			modifiedDate: "2023-10-08",
			link: "/media/documents/Del_Complex_Incident_Report_7_April_2023.pdf",
		},
	];

	return (
		<Portal
			category="Employee"
			tabs={tabs}
			newsArticles={newsArticles}
			resources={resources}
		/>
	);
};

export default PortalEmployee;
