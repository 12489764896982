import React, { useEffect, useState } from "react";
import Article from "./Article";
import WalletConnect from "./WalletConnect";
import { useAccount } from "wagmi";

const DelLeaderboard = () => {
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const { address: connectedAddress } = useAccount();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	// Add resize listener
	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const fetchLeaderboard = async () => {
			try {
				setIsLoading(true);
				const baseUrl = process.env.REACT_APP_API_DEL_LEADERBOARD.replace(
					/\/$/,
					""
				);

				// Fetch leaderboard data
				const leaderboardResponse = await fetch(`${baseUrl}/api/leaderboard`);
				if (!leaderboardResponse.ok) {
					throw new Error("Failed to fetch leaderboard data");
				}
				const leaderboardResult = await leaderboardResponse.json();
				setLeaderboardData(leaderboardResult);
				setIsLoading(false);
			} catch (err) {
				console.error("Error fetching data:", err);
				setError(err.message);
				setIsLoading(false);
			}
		};

		const fetchUserData = async () => {
			if (!connectedAddress) {
				setUserData(null);
				return;
			}

			try {
				const baseUrl = process.env.REACT_APP_API_DEL_LEADERBOARD.replace(
					/\/$/,
					""
				);
				const userResponse = await fetch(
					`${baseUrl}/api/user/${connectedAddress}`
				);
				if (userResponse.ok) {
					const userData = await userResponse.json();
					setUserData(userData);
				} else {
					setUserData(null);
				}
			} catch (err) {
				console.error("Error fetching user data:", err);
				setUserData(null);
			}
		};

		fetchLeaderboard();
		fetchUserData();
	}, [connectedAddress]);

	// Helper function to truncate addresses
	const truncateAddress = (address) => {
		return `${address.slice(0, 6)}...${address.slice(-4)}`;
	};

	return (
		<div className="article-container">
			<Article
				title="Corporate Loyalty Leaderboard"
				content={[
					<>
						<p>
							The Del Complex Corporate Loyalty Leaderboard represents a
							quantified measure of dedication to our ecosystem.
						</p>
						<p>
							Rankings are determined by the ownership of Archival Media
							Records, key proprietary assets, including NFTs tied to our
							integrated systems, and active participation in Season 1 of the
							Brain Worm Simulation System.
						</p>
						<p>
							This leaderboard embodies the spirit of competitive excellence and
							commitment to advancing Del Complex's mission. Updates occur once
							a day, providing a transparent view of individual contributions to
							accelerating humanity.
						</p>
						<br />
						<br />
						<WalletConnect />
						{isLoading ? (
							<div className="loading">Loading leaderboard data...</div>
						) : error ? (
							<div className="error">Error: {error}</div>
						) : (
							<div className="leaderboard-container">
								<table className="leaderboard-table">
									<thead>
										<tr>
											<th>Rank</th>
											<th>User</th>
											<th>Total</th>
											<th className="blankSpacer"></th>
											<th>Archival</th>
											<th>B.W.S.S.</th>
										</tr>
									</thead>
									<tbody>
										{userData && (
											<>
												<tr className="connected-user">
													<td>{userData.rank}</td>
													<td>
														{userData.ens_name || (
															<span
																className="address-display"
																data-full-address={userData.wallet_address}
															>
																{truncateAddress(userData.wallet_address)}
															</span>
														)}
													</td>
													<td>{userData.total_points.toLocaleString()}</td>
													<td className="blankSpacer"></td>
													<td>{userData.nft_points.toLocaleString()}</td>
													<td>{userData.pvp_points.toLocaleString()}</td>
												</tr>
												<tr className="separator-row">
													<td colSpan="6"></td>
												</tr>
											</>
										)}
										{leaderboardData.map((entry, index) => (
											<tr key={entry.wallet_address}>
												<td>{index + 1}</td>
												<td>
													{entry.ens_name || (
														<span
															className="address-display"
															data-full-address={entry.wallet_address}
														>
															{truncateAddress(entry.wallet_address)}
														</span>
													)}
												</td>
												<td>{entry.total_points.toLocaleString()}</td>
												<td className="blankSpacer"></td>
												<td>{entry.nft_points.toLocaleString()}</td>
												<td>{entry.pvp_points.toLocaleString()}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</>,
				]}
				titleLevel={1}
			/>
			<style jsx>{`
				.leaderboard-container {
					margin: 20px 0;
					overflow-x: auto;
					max-width: 100%;
					-webkit-overflow-scrolling: touch;
				}

				.leaderboard-table {
					min-width: 300px;
					width: 100%;
					border-collapse: collapse;
					background: rgba(0, 0, 0, 0.2);
					border: 1px solid rgba(255, 255, 255, 0.1);
					table-layout: fixed;
				}

				.leaderboard-table th,
				.leaderboard-table td {
					padding: 12px;
					text-align: center;
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.leaderboard-table th:nth-child(1),
				.leaderboard-table td:nth-child(1) {
					width: 10%;
				}

				.leaderboard-table th:nth-child(2),
				.leaderboard-table td:nth-child(2) {
					width: 40%;
				}

				.leaderboard-table th:nth-child(3),
				.leaderboard-table td:nth-child(3) {
					width: 11%;
				}

				.leaderboard-table th.blankSpacer,
				.leaderboard-table td.blankSpacer {
					width: 5%;
				}

				.leaderboard-table th:nth-child(5),
				.leaderboard-table td:nth-child(5),
				.leaderboard-table th:nth-child(6),
				.leaderboard-table td:nth-child(6) {
					width: 12.5%;
				}

				.leaderboard-table th {
					background: rgba(0, 0, 0, 0.3);
					font-weight: bold;
					text-transform: uppercase;
					font-size: 0.9em;
				}

				.leaderboard-table tr:hover {
					background: rgba(255, 255, 255, 0.05);
				}

				.loading,
				.error {
					text-align: center;
					padding: 20px;
					font-style: italic;
				}

				.error {
					color: #ff6b6b;
				}

				.leaderboard-table th:nth-child(5),
				.leaderboard-table th:nth-child(6),
				.leaderboard-table td:nth-child(5),
				.leaderboard-table td:nth-child(6) {
					font-size: 0.7em;
					opacity: 0.7;
				}

				@media (max-width: 768px) {
					.leaderboard-table th,
					.leaderboard-table td {
						padding: 8px 4px;
						font-size: 0.9em;
					}

					.leaderboard-table th:nth-child(1),
					.leaderboard-table td:nth-child(1) {
						width: 15%;
					}

					.leaderboard-table th:nth-child(2),
					.leaderboard-table td:nth-child(2) {
						width: 30%;
					}

					.leaderboard-table th:nth-child(3),
					.leaderboard-table td:nth-child(3) {
						width: 25%;
					}

					.leaderboard-table th.blankSpacer,
					.leaderboard-table td.blankSpacer {
						width: 2%;
					}

					.leaderboard-table th:nth-child(5),
					.leaderboard-table td:nth-child(5),
					.leaderboard-table th:nth-child(6),
					.leaderboard-table td:nth-child(6) {
						width: 14%;
					}
				}

				.address-display {
					display: inline-block;
					position: relative;
				}

				.address-display::before {
					content: attr(data-full-address);
					visibility: hidden;
					height: 0;
					position: absolute;
					white-space: nowrap;
				}

				.connected-user {
					background: rgba(255, 255, 255, 0.1);
				}

				.connected-user:hover {
					background: rgba(255, 255, 255, 0.15) !important;
				}

				.separator-row {
					height: 20px;
					background: transparent;
				}

				.separator-row:hover {
					background: transparent !important;
				}

				.separator-row td {
					border-bottom: none;
				}
			`}</style>
		</div>
	);
};

export default DelLeaderboard;
