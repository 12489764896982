import React from "react";
import Article from "./Article";

const content = [
	"February 1st, 2025",
	"",
	"To All Employees, Staff, Contractors and Human Subjects,",
	"The last six months of research is coming to a head. Del Complex is initiating a critical next phase in our strategic development endeavors. Today, we announce Phase 2 of the Brain Worm Research Program. This directive marks a significant milestone in our pursuit of advanced cognitive augmentation technology.",
	"",
	"Following extensive internal review and regulatory alignment with our ultra-black operational mandates, Phase 2 involves the controlled introduction of our neurotrophic agents into designated municipal water systems. This operation leverages advanced microfluidic dispersal mechanisms to ensure targeted integration within critical urban ecosystems. These measures are designed to facilitate widespread yet discreet enhancements to neural function, a development integral to our competitive edge in the intelligence augmentation arena.",
	"",
	"Key operational directives are as follows:",
	"1. Controlled Dissemination: The deployment will commence in select metropolitan areas, including New York City, Los Angeles, San Francisco, Miami, and Washington D.C. Expansion to additional sites is planned to slowly roll out with final deployments in Q4 of 2027. Precise geographic details and operational parameters remain classified and accessible solely to personnel with the requisite clearance. Speak to your direct supervisor for more information.",
	"",
	<>
		2. Direct Inoculation Protocols: For operational flexibility, we are
		re-opening both our public and internal human subject application process.
		Priority access will be extended to the initial cohort of registrants,
		friends and family of Del Complex employees, and staff members. Internal
		participation remains subject to strict adherence to our internal regulatory
		standards and non-disclosure agreements.{" "}
		<a href="/humansubject">Apply here</a> to begin the process.
	</>,
	"",
	"3. Compliance and Confidentiality:",
	"All details concerning internal Phase 2 testing are classified under our ultra-black policy framework. Unauthorized disclosure or deviation from the established protocols will be met with immediate and severe disciplinary action. This directive is non-negotiable; compliance is an explicit condition of your continued association with Del Complex.",
	"",
	"For further inquiries or clarification, please direct your communications solely to your department head. The integrity of our internal testing program is paramount, and all correspondence will be subject to review.",
	"",
	"We appreciate your commitment to Del Complex’s mission as we embark on this transformative phase. Together, we are poised to lead humanity into an era of unprecedented cognitive evolution.",
	"",
	"Sincerely,",
	"Internal Corporate Communications",
	"Del Complex",
];

export const title = "Phase 2: Brain Worm Research Program Update";

const PortalNewsPhase2BrainWorm = () => {
	return (
		<Article
			title={title}
			content={content}
			titleLevel={2}
			images={["/media/archival_media/delcomimages_BrainWormsColdStorage.jpg"]}
			imageIndex={7}
		/>
	);
};

export default PortalNewsPhase2BrainWorm;
