import React from "react";
import Portal from "./Portal";
import PortalNewsHiringEnd, { title as endTitle } from "./PortalNewsHiringEnd";
import PortalNewsContractorRequestBlue, {
	title as blueTitle,
} from "./PortalNewsContractorRequestBlue";
import PortalNewsETRLeak, { title as leakTitle } from "./PortalNewsETRLeak";
import PortalNewsAgusutaLostFound, {
	title as lostFoundTitle,
} from "./PortalNewsAgusutaLostFound";

import PortalNewsChimeraHealthNotice, {
	title as healthNoticeTitle,
} from "./PortalNewsChimeraHealthNotice";

import WhistleblowerLeak, {
	title as whistleblowerTitle,
} from "./PortalNewsWhistleblowerLeak";

import PortalTragicIncident, {
	title as tragicIncidentTitle,
} from "./PortalTragicIncident";

import PortalNewsPhase2BrainWorm, {
	title as phase2BrainWormTitle,
} from "./PortalNewsPhase2BrainWorm";

const PortalContractor = () => {
	const tabs = [
		{ id: "home", label: "Home" },
		{ id: "resources", label: "Resources" },
	];

	const newsArticles = [
		{ component: PortalNewsPhase2BrainWorm, title: phase2BrainWormTitle },
		{ component: PortalTragicIncident, title: tragicIncidentTitle },
		{ component: WhistleblowerLeak, title: whistleblowerTitle },
		{ component: PortalNewsChimeraHealthNotice, title: healthNoticeTitle },
		{ component: PortalNewsAgusutaLostFound, title: lostFoundTitle },
		{ component: PortalNewsETRLeak, title: leakTitle },
		{ component: PortalNewsHiringEnd, title: endTitle },
		{ component: PortalNewsContractorRequestBlue, title: blueTitle },
	];
	const resources = [
		{
			name: "BSFCC Construction",
			description: "Summer construction",
			type: "JPG",
			createdDate: "2023-07-21",
			modifiedDate: "2022-10-19",
			link: "/media/documents/BlueSeaBargeConstruction.jpg",
		},
		{
			name: "EIP Augusta Leakage",
			description: "Environmental Impact Report",
			type: "PDF",
			createdDate: "2023-10-05",
			modifiedDate: "2022-10-08",
			link: "/media/documents/Mt_Augusta_Energy_Storage_Leakage_Incident.pdf",
		},
		{
			name: "Augusta Visitors Guide",
			description: "Facilities Infra Group",
			type: "PDF",
			createdDate: "2021-07-02",
			modifiedDate: "2022-10-08",
			link: "/media/documents/MtAugustaVisitorsGuide.pdf",
		},
	];

	return (
		<Portal
			category="Contractor"
			tabs={tabs}
			newsArticles={newsArticles}
			resources={resources}
		/>
	);
};

export default PortalContractor;
