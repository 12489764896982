// navData.js
export const navLinks = [
	{
		title: "About",
		items: [
			{ name: "About Us", url: "/about", bgPrefix: "b" },
			{ name: "Facilities", url: "/facilities", bgPrefix: "g" },
			{ name: "Careers", url: "/careers", bgPrefix: "e" },
			{ name: "Updates", url: "/updates", bgPrefix: "b" },
		],
	},
	{
		title: "Research",
		items: [
			{ name: "Overview", url: "/research-overview", bgPrefix: "n" },
			{ name: "AGI", url: "/agi", bgPrefix: "l" },
			{ name: "BCI and PsyNet", url: "/bci", bgPrefix: "p" },
			{ name: "Global Resilience", url: "/global", bgPrefix: "h" },
			{ name: "Robotics", url: "/robotics", bgPrefix: "m" },
			{ name: "Clean Energy", url: "/clean-energy", bgPrefix: "j" },
		],
	},
	{
		title: "Products",
		items: [
			{
				name: "Surplus Shop",
				url: "https://shop.delcomplex.com",
				bgPrefix: "b",
			},
			{ name: "Archival Media", url: "/archival-media", bgPrefix: "b" },
			{ name: "Brain Worms", url: "/brainworms", bgPrefix: "bw" },
			{ name: "Loyalty Leaderboard", url: "/loyalty", bgPrefix: "b" },
		],
	},
];

export const backgroundOverrides = [
	{
		items: [
			{
				url: "/blue-sea-frontier",
				bgPrefix: "p",
			},
		],
	},
];
